import React from 'react';
import queryString from 'query-string';

import Button from '../../library/button/button';
import Image from '../../library/image/image';

import { useMessage } from '../../shared/hooks/hooks';

import { getAffiliateMarketingParams } from '../../shared/utils/url-params/url-params';

import { DT_GEOLOCATING_STORE_SEARCH_EXPANDED_RADIUS } from '../../shared/constants/messages';

import './store-locator-message.scss';

interface StoreLocatorMessageProps {
  className?: string;
  isValidSearch?: boolean;
  latestSearch?: string;
  onShopTireRackClick?: () => void;
}

function StoreLocatorMessage(props: StoreLocatorMessageProps) {
  const {
    className = '',
    isValidSearch,
    latestSearch,
    onShopTireRackClick
  } = props;
  const affiliateMarketingUrlParams = queryString.stringify(
    getAffiliateMarketingParams()
  );

  let urlParams = affiliateMarketingUrlParams
    ? `?${affiliateMarketingUrlParams}`
    : '';

  const messages = useMessage([DT_GEOLOCATING_STORE_SEARCH_EXPANDED_RADIUS]);

  return (
    <div className={className} styleName="message-container">
      <Image
        alt="Tire Rack Logo"
        loading="lazy"
        src="https://www.tirerack.com/content/dam/tirerack/desktop/Navigation/TireRackLogo.png"
        styleName="logo"
      />
      <div styleName="content">
        <>
          {isValidSearch ? (
            <p>
              The nearest store to &quot;{latestSearch}&quot; is more than&nbsp;
              <strong>
                {messages?.[DT_GEOLOCATING_STORE_SEARCH_EXPANDED_RADIUS] ||
                  '150'}
                &nbsp;miles
              </strong>
              &nbsp;away.
            </p>
          ) : (
            <p>There are no stores to show based on your query.</p>
          )}
          <p>
            Head to Tire Rack to shop for tires, wheels, and accessories online
            with local installation options across the nation.
          </p>
          <p>
            Since 2022, Tire Rack has been a part of the Discount Tire family.
            This combines the best of both worlds; Tire Rack&apos;s first-class
            online shopping experience and Discount Tire&apos;s first-class
            in-store service.
          </p>
        </>
      </div>
      <Button
        href={`${process.env.__TIRE_RACK_HOST__}${urlParams}`}
        isFullWidth
        isPrimary
        onClick={() => onShopTireRackClick?.()}
        styleName="shop-button"
        target="_blank"
      >
        Shop Tire Rack
      </Button>
    </div>
  );
}

export default StoreLocatorMessage;
